import { Box, Button, Modal, Typography, useTheme } from "@mui/material";
import { useStyles } from "./styles";
import clsx from "clsx";

const ModalPopup = ({
  title,
  message,
  buttonText,
  open,
  btnAction,
  buttonText2,
  btn2Action,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <Modal
      className={classes.popup}
      data-testid="popup"
      open={open}
      disableAutoFocus
    >
      <Box className={classes.modalWrapper}>
        <Typography
          data-testid="popupTitle"
          className={clsx(classes.text, classes.fontBold)}
          variant="h5"
          align="center"
        >
          {title}
        </Typography>
        <Typography
          data-testid="popupMessage"
          className={clsx(classes.text, classes.multiline)}
          variant="body2"
        >
          {message}
        </Typography>
        <Box className={classes.btnWrapper}>
          <Button
            className={classes.link}
            variant="contained"
            data-testid="btn"
            onClick={btnAction}
          >
            {buttonText}
          </Button>
          <Button
            className={classes.link}
            data-testid="btn2"
            variant="contained"
            onClick={btn2Action}
          >
            {buttonText2}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalPopup;
