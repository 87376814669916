import React, { useEffect, useState } from "react";
import { useStyles } from "./style";
import clsx from "clsx";
import { inRange, filter, some } from "lodash";
import { Box, useTheme, Typography } from "@mui/material";
import ColumnConfigHead from "./columnsHeaders/dateHeaders";
import ColumnConfigHead2 from "./columnsHeaders/yieldHeaders";
import ColumnConfigHead3 from "./columnsHeaders/takaHeaders";
import ColumnConfigBody from "./columnsBody/dateColumns";
import ColumnConfigBody2 from "./columnsBody/yieldColumns";
import ColumnConfigBody3 from "./columnsBody/takaColumns";
import CustomPagination from "./customPagination/CustomPagination";
import ColumnSort from "./utils/columnSort";
import { commonMinWidthFunction } from "./utils/commonMinWidth";
import { applyFilter } from "./utils/lotnumberfilter";
import { descendingComparator } from "./utils/descendingComparator";
import LoadingSpinner from "components/loadingSpinner";
import constants from "../../../../constants/constants.json";

const Table = ({
  selectedDates,
  filterBy,
  showHideList,
  setCurrentFilteredData,
  setAllRecords,
  setAllData,
  searchChange,
  setSearchChange,
  selectedStages,
  clearFilters,
  setClearFilters,
  stageFilter,
}) => {
  const theme = useTheme();
  const isDarkMode = useTheme().palette.mode === "dark";
  const classes = useStyles({ theme, isDarkMode });

  //Sort
  const [sortOrder, setSortOrder] = useState("initial");
  const [sortBy, setSortBy] = useState("");

  const [lotStatusData, setLotStatusData] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [totalFilterPages, setTotalFilterPages] = useState(0);

  const [dataPerPageState, setDataPerPageState] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [currentRecords, setCurrentRecords] = useState([]);
  const [isRecordsUpdated, setIsRecordsUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    React.$fetch(React.$apis.amkorLotDataView)
      .then((res) => {
        const result = res.data;
        const sortedData = result.sort(
          (a, b) => -descendingComparator(a, b, "LotNumber")
        );
        toggleSortOrder("LotNumber");
        setLotStatusData(sortedData);
        setCurrentRecords(sortedData);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        console.log("Error in Fetching Amkor Lot Data");
      });
  }, []);

  useEffect(() => {
    const copiedData = [...lotStatusData];
    let stageData = [];
    switch (stageFilter.id) {
      case 1:
        stageData = copiedData.filter(
          (row) =>
            row.DieReceipt &&
            !row.BumpIn &&
            !row.BumpOut &&
            !row.ProbeIn &&
            !row.ProbeOut &&
            !row.AssemblyIn &&
            !row.AssemblyOut &&
            !row.TestIn &&
            !row.TestOut &&
            !row.ShipOut
        );
        break;
      case 2:
        stageData = copiedData.filter(
          (row) =>
            row.BumpIn &&
            !row.BumpOut &&
            !row.ProbeIn &&
            !row.ProbeOut &&
            !row.AssemblyIn &&
            !row.AssemblyOut &&
            !row.TestIn &&
            !row.TestOut &&
            !row.ShipOut
        );
        break;
      case 3:
        stageData = copiedData.filter(
          (row) =>
            row.BumpOut &&
            !row.ProbeIn &&
            !row.ProbeOut &&
            !row.AssemblyIn &&
            !row.AssemblyOut &&
            !row.TestIn &&
            !row.TestOut &&
            !row.ShipOut
        );
        break;
      case 4:
        stageData = copiedData.filter(
          (row) =>
            row.ProbeIn &&
            !row.ProbeOut &&
            !row.AssemblyIn &&
            !row.AssemblyOut &&
            !row.TestIn &&
            !row.TestOut &&
            !row.ShipOut
        );
        break;
      case 5:
        stageData = copiedData.filter(
          (row) =>
            row.ProbeOut &&
            !row.AssemblyIn &&
            !row.AssemblyOut &&
            !row.TestIn &&
            !row.TestOut &&
            !row.ShipOut
        );
        break;
      case 6:
        stageData = copiedData.filter(
          (row) =>
            row.AssemblyIn &&
            !row.AssemblyOut &&
            !row.TestIn &&
            !row.TestOut &&
            !row.ShipOut
        );
        break;
      case 7:
        stageData = copiedData.filter(
          (row) =>
            row.AssemblyOut && !row.TestIn && !row.TestOut && !row.ShipOut
        );
        break;
      case 8:
        stageData = copiedData.filter(
          (row) => row.TestIn && !row.TestOut && !row.ShipOut
        );
        break;
      case 9:
        stageData = copiedData.filter((row) => row.TestOut && !row.ShipOut);
        break;
      case 10:
        stageData = copiedData.filter((row) => row.ShipOut && !row.ShipOut);
        break;
      case 11:
        stageData = copiedData.filter((row) => row.ShipOut);
        break;
      case 12:
        stageData = copiedData.filter((row) => row.ShipOut);
        break;
      default:
        stageData = copiedData;
        break;
    }
    setCurrentRecords(stageData);
  }, [lotStatusData, stageFilter.id]);

  useEffect(() => {
    if (searchChange) {
      setSearchChange(false);
    }
  }, [searchChange, setSearchChange]);

  useEffect(() => {
    if (clearFilters) {
      const copiedData = [...lotStatusData];
      setCurrentRecords(copiedData);
      setCurrentPage(1);
    }
    setClearFilters(false);
  }, [clearFilters, setClearFilters, lotStatusData]);

  useEffect(() => {
    let copiedData = [...lotStatusData];
    if (filterBy !== "") {
      const filteredData = applyFilter(lotStatusData, filterBy);
      copiedData = [...filteredData];
      setIsRecordsUpdated(true);
    }
    let startDate = new Date();
    let endDate = new Date();
    if (selectedDates.length === 2) {
      startDate = new Date(selectedDates[0]);
      endDate = new Date(selectedDates[1]);

      const result = filter(copiedData, (item) => {
        const dateFields = {
          dieReceiptDate: new Date(item?.DieReceipt),
          bumpInDate: new Date(item?.BumpIn),
          bumpOutDate: new Date(item?.BumpOut),
          probeInDate: new Date(item?.ProbeIn),
          probeOutDate: new Date(item?.ProbeOut),
          assemblyInDate: new Date(item?.AssemblyIn),
          assemblyOutDate: new Date(item?.AssemblyOut),
          testInDate: new Date(item?.TestIn),
          testOutDate: new Date(item?.TestOut),
          shipOutDate: new Date(item?.ShipOut),
        };

        const filterResult = some([
          selectedStages.dieReceipt &&
            inRange(dateFields.dieReceiptDate, startDate, endDate),
          selectedStages.bump &&
            (inRange(dateFields.bumpInDate, startDate, endDate) ||
              inRange(dateFields.bumpOutDate, startDate, endDate)),
          selectedStages.probe &&
            (inRange(dateFields.probeInDate, startDate, endDate) ||
              inRange(dateFields.probeOutDate, startDate, endDate)),
          selectedStages.assembly &&
            (inRange(dateFields.assemblyInDate, startDate, endDate) ||
              inRange(dateFields.assemblyOutDate, startDate, endDate)),
          selectedStages.test &&
            (inRange(dateFields.testInDate, startDate, endDate) ||
              inRange(dateFields.testOutDate, startDate, endDate)),
          selectedStages.ship &&
            inRange(dateFields.shipOutDate, startDate, endDate),
        ]);

        return filterResult;
      });
      setCurrentRecords(result);
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates, selectedStages, searchChange]);

  useEffect(() => {
    //search
    const copiedData = [...currentRecords];
    if (copiedData?.length) {
      if (searchChange) setCurrentPage(1);
      const filteredData = applyFilter(copiedData, filterBy);
      setCurrentRecords(filteredData);
      if (filterBy === "" && !isRecordsUpdated) {
        setCurrentRecords(lotStatusData);
        setIsRecordsUpdated(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchChange]);

  useEffect(() => {
    setCurrentFilteredData(currentPageData);
  }, [currentPageData, setCurrentFilteredData]);

  useEffect(() => {
    setAllRecords(currentRecords);
  }, [currentRecords, setAllRecords]);

  useEffect(() => {
    const copiedRecords = [...currentRecords];
    const sortedDataValue = ColumnSort(
      currentRecords,
      sortBy,
      sortOrder,
      copiedRecords
    );

    setCurrentRecords(sortedDataValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortOrder]);

  useEffect(() => {
    setAllData(lotStatusData);
  }, [setAllData, lotStatusData]);

  useEffect(() => {
    const copiedCurrentRecords = [...currentRecords];
    const startIndex = (currentPage - 1) * dataPerPageState;
    const endIndex = Math.min(
      startIndex + dataPerPageState,
      copiedCurrentRecords.length
    );
    const data = copiedCurrentRecords.slice(startIndex, endIndex);
    setCurrentPageData(data);
    setTotalFilterPages(
      Math.ceil(copiedCurrentRecords.length / dataPerPageState)
    );
    setTotalPages(copiedCurrentRecords.length);
  }, [currentPage, dataPerPageState, currentRecords]);

  const toggleSortOrder = (columnName) => {
    setSortBy(columnName);
    setSortOrder((prevOrder) => {
      if (prevOrder === "asc") {
        return "desc";
      } else if (prevOrder === "desc") {
        return "asc";
      } else {
        return "asc";
      }
    });
  };

  const handlePageChange = (_, page) => {
    setCurrentPage(page);
  };

  const onRowsPerPageChange = (e) => {
    setCurrentPage(1);
    setDataPerPageState(parseInt(e.target.value, 10));
  };

  const paginationFilterLogic = filterBy && filterBy !== "";

  let content;

  if (isLoading) {
    content = <LoadingSpinner loadingMessage={constants.loading} />;
  } else if (currentPageData && currentPageData.length > 0) {
    content = currentPageData.map((data, i) => (
      <Box
        className={classes.lotsContentWrapper}
        key={data.LotNumber}
        sx={{
          borderBottom: `1px solid ${isDarkMode ? "#FFFFFF" : "#5b5d6e"}`,
          minWidth: commonMinWidthFunction(
            showHideList.yield,
            showHideList.dates,
            showHideList.duration
          ),
        }}
      >
        <ColumnConfigBody
          showHideList={showHideList}
          classes={classes}
          data={data}
        />
        <ColumnConfigBody2
          showHideList={showHideList}
          classes={classes}
          data={data}
        />
        <ColumnConfigBody3
          showHideList={showHideList}
          classes={classes}
          data={data}
        />
      </Box>
    ));
  } else {
    content = (
      <Box className={classes.noRowsWrapper}>
        <Typography sx={{ color: theme.palette.text.primary }} variant="h7">
          {constants.noResults}
        </Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.paginationContainer}>
      <Box
        data-testid="table"
        className={clsx(
          classes.lotsTableContainer,
          classes.themeBackgroundColor
        )}
      >
        <Box
          className={clsx(
            classes.lotsTableHeadersWrapper,
            classes.themeBackgroundColor
          )}
        >
          <Box
            className={clsx(
              classes.headersContainer,
              classes.themeBackgroundColor,
              classes.themeColor
            )}
            sx={{
              minWidth: commonMinWidthFunction(
                showHideList.yield,
                showHideList.dates,
                showHideList.duration
              ),
              borderBottom: `1px solid ${isDarkMode ? "#FFFFFF" : "#5b5d6e"}`,
            }}
          >
            <ColumnConfigHead
              showHideList={showHideList}
              classes={classes}
              toggleSortOrder={toggleSortOrder}
              sortOrder={sortOrder}
              sortBy={sortBy}
            />
            <ColumnConfigHead2
              showHideList={showHideList}
              classes={classes}
              toggleSortOrder={toggleSortOrder}
              sortOrder={sortOrder}
              sortBy={sortBy}
            />
            <ColumnConfigHead3
              showHideList={showHideList}
              classes={classes}
              toggleSortOrder={toggleSortOrder}
              sortOrder={sortOrder}
              sortBy={sortBy}
            />
          </Box>

          {content}
        </Box>
      </Box>

      <CustomPagination
        classes={classes}
        paginationFilterLogic={paginationFilterLogic}
        totalFilterPages={totalFilterPages}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        filteredData={currentPageData}
        lotStatusData={lotStatusData}
        dataPerPageState={dataPerPageState}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Box>
  );
};

export default Table;
