import ReactDom from "react-dom/client";

import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import { myAdvaLogin } from "./config/secrets";
import "./assets/style/minireset.css";
import "./assets/style/base.scss";
import "./assets/style/layout.scss";
import App from "./App";
import "./middleware/reference";

const root = ReactDom.createRoot(document.getElementById("root"))

const authorizationParams = {
  scope: "openid profile email offline_access",
  redirect_uri: window.location.origin,
  audience: myAdvaLogin.audience,
};
root.render(
  <Auth0Provider
    domain={myAdvaLogin.domain}
    clientId={myAdvaLogin.clientId}
    authorizationParams={authorizationParams}
    useRefreshTokens={true}
    cacheLocation="memory"
  >
    <App />
  </Auth0Provider>,
);

serviceWorker.unregister();
