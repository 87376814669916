import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
    modalWrapper: {
        padding: '15px',
        marginLeft: '1%',
        alignSelf: "center",
        height: "auto",
        boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.2)",
        borderRadius: "10px",
        backgroundColor: ({ theme }) => theme.palette.bgColor
    },
    text: {
        color: ({ theme }) => theme.palette.text.primary,
        marginBottom: '2%'
    },
    link: {
        fontWeight: "600px",
        fontSize: "0.7vw",
        height: "2vw",
        fontFamily: "Rubik",
        textAlign: "center",
        color: "#FFFFFF",
        backgroundColor: "#B80351",
        "&:hover": {
            backgroundColor: "#B80351",
        },
    },
    popup: {
        display: 'flex',
        justifyContent: 'center',
    },
    btnWrapper: {
        display: 'flex',
        justifyContent: 'center',
        gap: '10%'
    },
    multiline: {
        whiteSpace: 'pre-line'
    },
    fontBold: {
        fontWeight: '600'
    }
})