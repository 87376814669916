import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import { filter, some } from "lodash";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Button,
  Typography,
  Stack,
  Tooltip,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import loadAuth from "utils/loadAuth";
import LoadingSpinner from "components/loadingSpinner";
import AdvanceFilterModal from "components/modals/advanceFilterModal";
import Emptystate from "components/emptyState";
import { useAppContext } from "context/appContext";
import { useStyles } from "./style";
import { useTheme } from "@emotion/react";
import constants from "../../constants/constants.json";
import { sortCards, getOverviewLevel1 } from "./homePage.services";
import { useNavigate } from "react-router";
import PropsTypes from "prop-types";
import { cookiesGet } from "utils/tools";

const pageSize = 8;

const HomePage = ({ isNavbarOpen }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    searchQuery,
    sortOption,
    filterOption,
    setShowAdvanceFilterOptions,
    showAdvanceFilterOptions,
    setFilterOption,
  } = useAppContext();
  const [products, setProducts] = useState([]);
  const [copiedProducts, setCopiedProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(filterOption.currentPage);
  const [totalItems, setTotalItems] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  const handlePageChange = useCallback(
    (_, page) => {
      const currentPage = Math.max(page, 1);
      setCurrentPage(currentPage);
      setFilterOption({
        ...filterOption,
        currentPage,
      });
    },
    [filterOption, setFilterOption]
  );

  const getProductLinks = (links) => links.map((l) => l.function_name);

  const getProductTypes = (products) => {
    const types = [];
    const links = [];

    const productTypesAndLinks = products.map((p) => ({
      type: p.product_type,
      links: getProductLinks(p.link_name),
    }));

    productTypesAndLinks.forEach((p) => {
      p.type !== constants.allProducts && types.push(p.type);
      links.push(...p.links);
    });

    const allProType = [...new Set(types)];
    const allProdLinks = [...new Set(links)];

    setFilterOption({
      ...filterOption,
      allproductType: allProType,
      allLinks: allProdLinks,
    });
  };

  const navigateToLink = (product, link) => {
    navigate(
      `/overview/entry/${product.product_id}/${Number(link.function_id)}`
    );
  };

  useEffect(() => {
    loadAuth(async () => {
      try {
        setIsLoading(true);
        const token = cookiesGet("jwtToken");
        if (!token) {
          setIsLoading(false);
          return;
        }
        const res = await getOverviewLevel1({ React });

        const tableList = res.data;

        tableList.items = tableList.items.filter(
          (item) => item.link_name.length > 0
        );

        tableList.items = tableList.items.sort((a, b) =>
          a.product_name?.localeCompare(b?.product_name)
        );

        if (tableList) {
          setProducts(tableList);
          setCopiedProducts(tableList);
          setTotalItems(tableList.total);
          setIsLoading(false);
          getProductTypes(tableList.items);
        }
      } catch (error) {
        setIsLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCopiedProducts({
      ...copiedProducts,
      items: sortCards({ data: copiedProducts.items, sortOption }),
    });
    handlePageChange(undefined, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOption]);

  const getFilteredProducts = ({
    newItems,
    totalItem,
    copiedProducts,
    filterOption,
  }) => {
    if (filterOption?.clearFilters) {
      newItems = copiedProducts.items;
    } else if (filterOption?.advanceFilter) {
      if (
        filterOption.links.length > 0 &&
        filterOption.productType.length > 0
      ) {
        newItems = filter(
          newItems,
          (item) =>
            filterOption.productType.includes(item.product_type) &&
            some(item.link_name, (link) =>
              filterOption.links.includes(link.function_name)
            )
        );
      } else {
        newItems = filter(
          newItems,
          (item) =>
            filterOption.productType.includes(item.product_type) ||
            some(item.link_name, (link) =>
              filterOption.links.includes(link.function_name)
            )
        );
      }
      totalItem = newItems.length;
    } else {
      newItems = copiedProducts.items;
    }
    newItems = sortCards({
      data: newItems,
      sortOption,
    });
    return { newItems, totalItem };
  };

  useEffect(
    () => setCurrentPage(filterOption.currentPage),
    [filterOption.currentPage]
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (copiedProducts?.items) {
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = Math.min(
        startIndex + pageSize - 1,
        copiedProducts.total - 1
      );

      let newItems = copiedProducts.items;
      let totalItem = copiedProducts.items.length;

      const data = getFilteredProducts({
        newItems,
        totalItem,
        copiedProducts,
        filterOption,
        sortOption,
      });
      newItems = data.newItems;
      totalItem = data.totalItem;

      if (searchQuery) {
        const query = searchQuery.toLowerCase();
        newItems = newItems.filter((p) =>
          p.product_name.toLowerCase().includes(query)
        );
        totalItem = newItems.length;
      }
      newItems = newItems.slice(startIndex, endIndex + 1);

      setTotalItems(totalItem);
      setProducts(newItems);

      if (!isLoading && totalItem <= pageSize) {
        setCurrentPage(1);
        setFilterOption({
          ...filterOption,
          currentPage: 1,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    copiedProducts,
    searchQuery,
    filterOption.productType,
    filterOption.links,
    sortOption,
    isLoading,
  ]);

  const classes = useStyles({
    isNavbarOpen,
    theme,
    count: Math.ceil(totalItems / pageSize),
  });

  const productTitleLength = 5;

  const getTitle = (product) => {
    if (product.product_name?.length > productTitleLength) {
      return product.product_name;
    }
    return "";
  };

  const isNeedOfWordBreak = (product) => {
    if (product.product_name?.includes(" ")) {
      return "break-word";
    }
    return "break-all";
  };

  const getProductType = (product) => {
    if (product?.product_type) {
      return product.product_type;
    }
    return "";
  };

  const renderProducts = (product) => {
    return (
      <Grid item xs={12} md={3} sm={6} key={product.product_id}>
        <Card className={classes.card} data-testid="productCard">
          <CardContent className={classes.cardContent}>
            <Grid
              container
              className={classes.grid}
              justifyContent={"space-between"}
            >
              <Grid item xs={5}>
                <div className={classes.content}>
                  <img
                    className={classes.productImg}
                    src={`${product.pic_path ?? "#"}`}
                    alt={`${product.product_name ?? ""}`}
                  />
                </div>
              </Grid>
              <Grid item xs={7} className={classes.nameWrapper}>
                <Tooltip title={getTitle(product)}>
                  <Typography
                    sx={{
                      wordBreak: isNeedOfWordBreak(product),
                    }}
                    className={classes.name}
                    variant="h6"
                  >
                    {product.product_name}
                  </Typography>
                </Tooltip>

                <Typography className={classes.type}>
                  {getProductType(product)}
                </Typography>
              </Grid>
            </Grid>
            <Divider className={classes.break} />
            <Stack direction={"column"} sx={{ mx: 1 }} spacing={1}>
              {product?.link_name?.map((link) => (
                <Button
                  key={`${link.function_id}`}
                  className={classes.link}
                  variant="contained"
                  onClick={() => navigateToLink(product, link)}
                >
                  {link.function_name}
                </Button>
              ))}
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  if (isLoading) {
    return (
      <Box className={classes.loadingWrapper}>
        <LoadingSpinner loadingMessage="Loading..." />
      </Box>
    );
  }

  return (
    <Box className={classes.cardsWrapper} data-testid="homePage">
      {products.length > 0 ? (
        <Box className={classes.container}>
          <Grid
            className={classes.productWrapper}
            container
            direction={"row"}
            justifyContent={"flex-start"}
            spacing={2}
          >
            {products.map((p) => renderProducts(p))}
          </Grid>
        </Box>
      ) : (
        <Emptystate
          title={constants.noResults}
          message={constants.noResultsMsg}
          isNavbarOpen={isNavbarOpen}
        />
      )}

      <Box
        id="page"
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Box className={classes.pagination}>
          <Pagination
            count={Math.ceil(totalItems / pageSize)}
            page={currentPage}
            shape="circular"
            onChange={handlePageChange}
            sx={{
              fontSize: "0.8vw !important",
              "& .MuiPaginationItem-root": {
                fontSize: "0.8vw !important",
              },
              "& .MuiButtonBase-root-MuiPaginationItem-root": {
                height: "2rem",
                width: "2rem",
                borderRadius: "50%",
              },

              "& .MuiSvgIcon-root": {
                fontSize: "1.4rem",
              },
            }}
          />
          <Typography
            data-testid="homePage.PageDetails"
            className={classes.pageText}
          >
            {(currentPage - 1) * pageSize + 1}-
            {Math.min((currentPage - 1) * pageSize + pageSize, totalItems)} of{" "}
            {totalItems}
          </Typography>
        </Box>
      </Box>

      <AdvanceFilterModal
        data-testid="homePage.AdvanceFilterModal"
        handleClose={() => setShowAdvanceFilterOptions((prev) => !prev)}
        isOpen={showAdvanceFilterOptions}
        handlePageChange={handlePageChange}
      />
    </Box>
  );
};

HomePage.propTypes = {
  isNavbarOpen: PropsTypes.bool,
};
export default HomePage;
