/*
 * @Description: axios request method
 * @Version: 1.0
 */

import axios from 'axios';
import { baseUrl, timeout } from 'config/settings';
import { navigateTo } from './history';
import { cookiesGet } from './tools';

/**
 * axios defaults headers config
 * axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'; //request header
 * axios.defaults.headers.post['Content-Type'] = 'multipart/form-data;boundary=' + Date.now(); //request header
 * axios.defaults.headers.get['Content-Type'] = 'application/json; charset=utf-8'; //request header
 * axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
 */

axios.defaults.baseURL = baseUrl; // request url
axios.defaults.timeout = timeout; // response time
axios.defaults.headers.get['Content-Type'] = 'application/json; charset=utf-8';

/**
 * @description: POST JSON Params Interceptor
 * @param {type}
 * @return {Promise}
 */
axios.interceptors.request.use((config) => {
  // config.responseType = 'text';

  //Request before data stringify
  // if (config.method === 'post') {
  //   config.data = qs.stringify(config.data);
  // }

  // Check API URL
  if (config.url.indexOf('://') !== -1) config.baseURL = '';
  // Authorization json web token
  if (cookiesGet('jwtToken')) {
    config.headers.Authorization = 'Bearer ' + cookiesGet('jwtToken');
    // config.headers.AuthorizationCookie = 'Bearer ' + localStorage.jwtToken;
    // config.headers.credentials = 'include';
  }

  // Authorization json web token
  //commented by lijing, because id token is not needed
  // if (localStorage.jwt_ID_Token) {
  //   config.headers.IDToken = 'Bearer ' + localStorage.jwt_ID_Token;
  // }
  // data stringify of output
  return config;
}, (error) => {
  return Promise.reject(error);
});

/**
 * @description: response interceptors
 * @param {type}
 * @return {Promise}
 */
axios.interceptors.response.use((res) => {
  //data manipulation 
  return res;
}, (error) => {
  console.log('error', error)
  if (error?.response?.status === 401 || error?.response?.status === 403) {
    return navigateTo("/unauthorized")
  }
  else if (error?.response?.status === 404) {
    return navigateTo("/notFound")
  }
  else if (error?.response?.status === 500) {
    return navigateTo("/error")
  }
  // console.log('Network anomaly', error, error.response.status);
  return Promise.reject(error.response);
});

/**
 * @description: FETCH request axios request
 * @param {String}
 * @return {Promise}
 */
export function $fetch(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params
    })
      .then(response => {
        return resolve(response)
      }).catch(error => {
        return reject(error)
      })
  })
}

/**
 * @description: POST request method
 * @param {String}
 * @return {Promise}
 */
export function $post(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, params)
      .then(response => {
        return resolve(response)
      }).catch(error => {
        return reject(error)
      })
  })
}

/**
 * @description: PATCH request method
 * @param {String}
 * @return {Promise}
 */
export function $patch(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, params)
      .then(response => {
        return resolve(response)
      }).catch(error => {
        return reject(error)
      })
  })
}

/**
 * @description: PUT request method
 * @param {String}
 * @return {Promise}
 */
export function $put(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, params)
      .then(response => {
        return resolve(response)
      }).catch(error => {
        return reject(error)
      })
  })
}
